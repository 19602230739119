import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
// import navigation components
import { FutureRents } from "./FutureRents";
import { CurrentRents } from "./CurrentRents";
import { PastRents } from "./PastRents";
import { useTranslation } from "react-i18next";
export const NavigationProducts = () => {
  const { t } = useTranslation("profile");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs value={value} onChange={handleChange} centered sx={{ py: 3 }}>
        <Tab label={t("tabs.Past")} sx={{ textTransform: "capitalize" }} />
        <Tab label={t("tabs.Current")} sx={{ textTransform: "capitalize" }} />
        <Tab label={t("tabs.Future")} sx={{ textTransform: "capitalize" }} />
      </Tabs>

      {value === 0 && <PastRents />}
      {value === 1 && <CurrentRents />}
      {value === 2 && <FutureRents />}
    </>
  );
};

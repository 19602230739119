import * as Yup from "yup";
import { useTranslation } from "react-i18next";
export const useSaveAddressValidation = () => {
  const { t } = useTranslation("settings");
  const saveAddressValidationSchema = Yup.object().shape({
    country: Yup.string().required(t("save-address.validation.country.required")),
    address: Yup.string().required(t("save-address.validation.address.required")),
   /*  apartment: Yup.string().required(t("save-address.validation.apartment.required")), */
    city: Yup.string().required(t("save-address.validation.city.required")),
    state: Yup.string().required(t("save-address.validation.state.required")),
  zipCode: Yup.string()
    .required(t("save-address.validation.zipCode.required"))
    .matches(/^(850[0-9]{2}|85[1-9][0-9]{2}|860[0-9]{2}|861[0-9]{2}|862[0-9]{2}|863[0-9]{2}|864[0-9]{2}|865[0-5][0-9]|86556)$/, t("save-address.validation.zipCode.invalid")),
   /*  location: Yup.object().shape({
      lat: Yup.number().required(t("save-address.validation.location.required")),
      lng: Yup.number().required(t("save-address.validation.location.required")),
    }), */
  });
  return { saveAddressValidationSchema };
};


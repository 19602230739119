import { Box } from "@mui/material";
import { SelectLocation } from "../general/SelectLocation";
import { useFormikContext } from "formik";
const ItemLocation = () => {
  const { values, setFieldValue } = useFormikContext();
  const onLocationChange = (location) => {
    setFieldValue("location", location);
  }
  return (
    <Box sx={{ my: 2 }}>
      <SelectLocation useNearbyLocation={true} onLocationChange={onLocationChange} location={values.location} />
    </Box>
  )
}

export default ItemLocation
import { Paper, Typography, Button, Stack } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useTranslation } from "react-i18next";
import { useStripeConnect } from "./hooks/useStripConnect";

export const AccountSetting = ({ isAccountCommplete }) => {
  const { t } = useTranslation("payment-settings");
  const { onBoardingLink, goToStripeDashboard, loadingStates } =
    useStripeConnect();

  const buttonStyles = {
    px: 4,
    fontSize: "1rem",
    width: ["300px", "auto"],
  };

  return (
    <Paper elevation={3} sx={{ p: 4, textAlign: "center", bgcolor: "#f8f9fa" }}>
      <AccountBalanceWalletIcon
        sx={{ fontSize: 60, color: "primary.main", mb: 2 }}
      />
      <Typography variant="h6" gutterBottom>
        {t("account-settings.title")}
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 3 }}>
        {t("account-settings.description")}
      </Typography>
      <Stack
        spacing={2}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        {!isAccountCommplete ? (
          <Button
            variant="contained"
            sx={buttonStyles}
            onClick={onBoardingLink}
            disabled={loadingStates.onboarding}
          >
            {t("account-settings.button1")}
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={buttonStyles}
            onClick={goToStripeDashboard}
            disabled={loadingStates.dashboard}
          >
            {t("account-settings.button2")}
          </Button>
        )}
      </Stack>
    </Paper>
  );
};

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

export const ConfirmationDialog = ({
    open,
    onClose,
    onConfirm,
    title = "Confirmation",
    message = "Are you sure you want to proceed with this action?",
    confirmButtonText = "Confirm",
    severity = "warning"
}) => {
    const { t } = useTranslation("confirmation-dialog");

    const getSeverityColor = () => {
        switch (severity) {
            case 'error':
                return 'error.main';
            case 'warning':
                return 'warning.main';
            case 'info':
                return 'info.main';
            default:
                return 'warning.main';
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                elevation: 3,
                sx: {
                    borderTop: 4,
                    borderColor: getSeverityColor(),
                    minWidth: 320
                }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <WarningIcon color={severity} />
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button
                    onClick={onClose}
                    color="inherit"
                    variant="outlined"
                >
                    {t("Cancel")}
                </Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                    color={severity}
                    variant="contained"
                >
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
import { useState, useEffect } from 'react';
import i18n from 'i18next';

export const GetLanguageName = (language) => {
    return new Intl.DisplayNames([language], { type: 'language' }).of(language)
}

export const useLanguageName = () => {
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = () => {
            setCurrentLanguage(i18n.language);
        };

        // Listen for language changes
        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup listener when component unmounts
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    return GetLanguageName(currentLanguage);
}
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const useRentDateValidation = (product) => {
  const { t } = useTranslation("product-details");

  const validationSchema = Yup.object().shape({
    From: Yup.date().required(t("rentDate.formErrors.from.required")),
    To: product.per === "Day"
      ? Yup.date()
          .required(t("rentDate.formErrors.to.required"))
          .min(Yup.ref("From"), t("rentDate.formErrors.to.endDateAfterStart"))
      : Yup.date().nullable(),
    number: product.per !== "Day"
      ? Yup.number()
          .required(t("rentDate.formErrors.number.required", { type: product.per }))
          .positive(t("rentDate.formErrors.number.positive", { type: product.per }))
      : Yup.number().nullable(),
  });

  return validationSchema;
};
import { useParams } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance"
import { useShowAlert } from "../../../hooks/useShowAlert";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export const useUpdateProduct = () => {
    const { id } = useParams();
    const { showAlert } = useShowAlert();
    const [loading, setLoading] = useState(false);
    const  navigate  = useNavigate();
    const handleSubmit = (values) => {
        setLoading(true);
        
        const updatedValues = {
            ...values,
            available: values.available === "Open"
        };
        
        axiosInstance
            .put(
                `/products/${id}`,
                updatedValues
            )
            .then((res) => {
                showAlert("success", res.data.message);
                navigate(-1);
            })
            .catch((err) => {
                showAlert("error", err.response.data.error);
            }).finally(() => {
                setLoading(false);
            });
    };
    return { handleSubmit, loading };
}
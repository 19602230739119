import { Avatar, Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTranslation } from "react-i18next";
import { useShowAlert } from "../../hooks/useShowAlert";
const UserInformation = ({ user, isOwner }) => {
  const { showAlert } = useShowAlert();
  const { t } = useTranslation("product-details");
  const authUser = useSelector(getUser); // get user from redux
  const navigate = useNavigate();
  // handle navigate to contact with user page

  const handleNavigateContactWithUserPage = () => {
    if (authUser) {
      if (user?._id === authUser?.id) {
        showAlert("error", "you can't contact with yourself");

        return;
      } else {
        navigate(`/contactWithUser/${user?._id}/${user?.displayName}`);
      }
    } else {
      showAlert("error", "Please login to contact with user");

      return;
    }
  };
  // handle go to user profile page
  const handleNavigateToUserProfile = () => {
    navigate(`/rent-profile/${user?._id}`);
  };
  return (
    <Box sx={{ mt: 5 }}>
      <Stack direction="row" spacing={2}>
        <Box>
          <Paper
            sx={{
              borderRadius: "50%",
              width: "100px",
              height: "100px",
              position: "relative",
            }}
            elevation={5}
          >
            <Avatar
              sx={{ textDecoration: "none", width: "100px", height: "100px" }}
            >
              {user?.profilePicture ? (
                <img
                  src={user.profilePicture}
                  alt={user?.displayName}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  loading="lazy"
                />
              ) : (
                user?.displayName?.[0].toUpperCase()
              )}
            </Avatar>
          </Paper>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {user?.displayName}
          </Typography>
          {!isOwner && (
            <Stack
              sx={{
                mt: 1,
                justifyContent: ["start", "center"],
                alignItems: "start",
                flexDirection: ["column", "row"],
              }}
            >
              <Button
                sx={{ color: "primary.main", textAlign: "start" }}
                onClick={handleNavigateToUserProfile}
              >
                {t("userInfo.viewProfile")}
              </Button>
              <Button
                variant="text"
                sx={{ color: "secondary.main" }}
                onClick={handleNavigateContactWithUserPage}
              >
                {t("userInfo.contact")}
              </Button>
            </Stack>
          )}
          <Stack spacing={2} direction="row" alignItems="center" sx={{pt:1}}>
            <StarBorderIcon sx={{ color: "primary.main" }} />
            <Typography variant="body2" component="span">
              {user?.rating} {t("userInfo.stars")}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default UserInformation;

import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export const useDeleteProduct = (productId) => {
  const { showAlert } = useShowAlert();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleDelete = () => {
    setLoading(true);
    axiosInstance
      .delete(`/products/${productId}`)
      .then((res) => {
        showAlert("success", res.data.message);
        navigate("/browse-listing");
      })
      .catch((err) => {
        showAlert("error", err.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { handleDelete, loading };
};

import { Box, Container, Stack, Typography } from "@mui/material";
import { useResetPasswordValidation } from "../../../validation/validationFormResetPassword";
import { useResetPassword } from "../hooks/useResetPassword";
import { useTranslation } from "react-i18next";
import CustomFormikField from "../../../formik/CustomFormikField";
import SubmitButton from "../../../formik/SubmitButton";
import BaseForm from "../../../formik/BaseForm";
import PasswordInput from "../../../formik/PasswordInput";
export const NewPasswordForm = () => {
    const { ResetPasswordValidationSchema } = useResetPasswordValidation();
    const { t } = useTranslation("resetPassword");
    const { handleSubmit, loading } = useResetPassword();

    const initialValues = {
        resetToken: "",
        newPassword: "",
    };
    return (
        <Box sx={{ py: 8 }}>
            <Container maxWidth="sm" sx={{ mt: 4 }}>
                <Box sx={{ p: 4, backgroundColor: "secondary.main", borderRadius: "10px" }}>
                    <BaseForm
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={ResetPasswordValidationSchema}
                    >
                        <Stack spacing={3}>
                            <Box>
                                <Typography variant="body1" sx={{ color: "text.main", pb: 1 }}>
                                    {
                                        t("resetPassword.enterCode.label")
                                    }
                                </Typography>
                                <CustomFormikField name="resetToken" placeholder={t("resetPassword.enterCode.label")} type="text" size="small" />
                            </Box>
                            <Box>
                                <Typography variant="body1" sx={{ color: "text.main", pb: 1 }}>
                                    {t("resetPassword.enterNewPassword.label")}
                                </Typography>
                                <PasswordInput name="newPassword" placeholder={t("resetPassword.enterNewPassword.label")} />
                            </Box>
                            <SubmitButton disabled={loading} >
                                {t("resetPassword.button")}
                            </SubmitButton>
                        </Stack>
                    </BaseForm>
                </Box>
            </Container>
        </Box>
    );
};

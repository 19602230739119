import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";
import axiosInstance from "../../axios/axiosInstance";
import { MessagesSkeleton } from "../general/loading/MessagesSkeleton";
import { MessageItem } from "./MessageItem";
import { useTranslation } from "react-i18next";
import InfiniteScrollList from "../general/InfiniteScrollLis";

export const MessagesHistory = () => {
    const { t } = useTranslation("chat");
    const theme = useTheme();
    const userId = useSelector(getUser)?.id;

    const fetchMessages = async (page, limit) => {
        try {
            const response = await axiosInstance.get(`/messages/${userId}`, {
                params: { page, limit },
            });
            return response.data.messages;
        } catch (error) {
            console.error("Error fetching messages:", error);
            return [];
        }
    };

    const LoadingComponent = () => (
        <Stack spacing={1} sx={{ width: "100%" }}>
            {Array.from({ length: 6 }).map((_, index) => (
                <MessagesSkeleton key={index} />
            ))}
        </Stack>
    );

    const RenderComponent = ({ items }) => (
        <Stack spacing={1} sx={{ width: "100%" }}>
            {items.map((message) => (
                <MessageItem key={message._id} message={message} theme={theme} />
            ))}
        </Stack>
    );

    return (
        <Box
            sx={{
                width: "100%",
                overflowY: "hide",
                border: "1px solid #ccc",
                borderRadius: "20px",
                padding: "20px",
                "&::-webkit-scrollbar": { width: "0.5em" },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: "20px",
                },
            }}
        >
            <InfiniteScrollList
                fetchData={fetchMessages}
                LoadingComponet={LoadingComponent}
                RenderComponent={RenderComponent}
                loadingText={t("loadingMessages")}
                noProductsText={t("noMessages")}
            />
        </Box>
    );
};
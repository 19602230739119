import { Stack, Typography } from "@mui/material";
import googleApp from "../../../assets/images/googlePlay.png";
import appleApp from "../../../assets/images/app-store.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const FooterAppDownloads = () => {
  const { t } = useTranslation("footer");
  return (
  <div>
    <Typography variant="body1" sx={{ color: "white", fontWeight: "bolder" }}>
      {t("getOurApp")}
    </Typography>
    <Stack spacing={1} mt={2}>
      <Link to="/">
        <img src={googleApp} alt="google app" loading="lazy" style={{ width: "120px", height: "100%" }} />
      </Link>
      <Link to="/">
        <img src={appleApp} alt="apple app" loading="lazy" style={{ width: "120px", height: "100%" }} />
      </Link>
    </Stack>
  </div>
);
}

export default FooterAppDownloads;

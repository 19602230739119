import { Stack, Box, Typography } from "@mui/material";
import { countrys } from "../../../helper/Countrys";
import { states } from "../../../helper/HandelStates";
import { useTranslation } from "react-i18next";
import CustomSelectBox from "../../../formik/CustomSelectBox";
import CustomFormikField from "../../../formik/CustomFormikField";
import { useFormikContext } from "formik";

const StateField = ({ t }) => {
  const { values } = useFormikContext();
  const stateOptions = states(values.country).map((state) => ({
    value: state,
    label: state,
  }));

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          color: "secondary.main",
          fontWeight: "bold",
          py: 2,
          fontFamily: "Poppins-Bold",
        }}
      >
        {t("save-address.labels.State")}
      </Typography>
      <CustomSelectBox
        name="state"
        label={t("save-address.labels.State")}
        options={stateOptions}
      />
    </Box>
  );
};

export const AddressInput = () => {
  const { t } = useTranslation("settings");
  const countryOptions = countrys.map((country) => ({
    value: country,
    label: country,
  }));
  return (
    <Box
      spacing={2}
      p={2}
      sx={{ borderRadius: "10px", backgroundColor: "#F7F7F7" }}
    >
      <Typography
        variant="h6"
        sx={{ color: "secondary.main", fontWeight: "500", py: 2 }}
      >
        {t("save-address.home")}
      </Typography>

      <Stack spacing={2}>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: "secondary.main",
              fontWeight: "bold",
              py: 2,
              fontFamily: "Poppins-Bold",
            }}
          >
            {t("save-address.labels.Country")}
          </Typography>
          <CustomSelectBox
            name="country"
            label={t("save-address.labels.Country")}
            options={countryOptions}
          />
        </Box>

        <StateField t={t} />
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: "secondary.main",
              fontWeight: "bold",
              py: 2,
              fontFamily: "Poppins-Bold",
            }}
          >
            {t("save-address.labels.City")}
          </Typography>
          <CustomFormikField
            name="city"
            label={t("save-address.labels.City")}
          />
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: "secondary.main",
              fontWeight: "bold",
              py: 2,
              fontFamily: "Poppins-Bold",
            }}
          >
            {t("save-address.labels.ZipCode")}
          </Typography>
          <CustomFormikField
            name="zipCode"
            label={t("save-address.labels.ZipCode")}
          />
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: "secondary.main",
              fontWeight: "bold",
              py: 2,
              fontFamily: "Poppins-Bold",
            }}
          >
            {t("save-address.labels.Address")}
          </Typography>
          <CustomFormikField
            name="address"
            label={t("save-address.labels.Address")}
          />
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: "secondary.main",
              fontWeight: "bold",
              py: 2,
              fontFamily: "Poppins-Bold",
            }}
          >
            {t("save-address.labels.Apartment")}
          </Typography>
          <CustomFormikField
            name="apartment"
            label={t("save-address.labels.Apartment")}
          />
        </Box>
      </Stack>
    </Box>
  );
};

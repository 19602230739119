import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps"
export const DisplayLoaction = ({ location }) => {
  const ApiKey = import.meta.env.VITE_VERCEL_MAP_API_KEY;
  return (
    <>
      {
          <APIProvider apiKey={ApiKey}>
          <Map
            zoom={10}
            center={{ lat: location ? location.lat : 0, lng: location ? location.lng : 0 }}
            mapId="e8b1b7b3b3b4b3b3"
            style={{ width: "100%", height: "150px" }}
          >
            <AdvancedMarker position={{ lat: location ? location.lat : 0, lng: location ? location.lng : 0 }} />
          </Map>
        </APIProvider>
      }
    </>
  )
}

import { Button, CircularProgress } from "@mui/material"

const SubmitButton = ({ children, ...props }) => {
    return (
        <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
                borderRadius: "10px", py: 1, mt: 2,
                '&.Mui-disabled': {
                    color: 'white',
                    cursor: 'not-allowed',
                }
            }}
            {...props}
        >
            {children}
            {
                props.disabled &&
                <CircularProgress color="primary" sx={{ml:2}} size={18} />
            }

        </Button>
    )
}

export default SubmitButton
import { Divider, Stack } from "@mui/material"
import { AllFilterKey } from "./AllFilterKey"
/* import CategoryList from "./CategoryList" */
import CategoryList from "./CategoryList"
const FilterContainer = () => {
  return (
    <Stack spacing={2} sx={{ width: "100%", px: 4, backgroundColor: "text.main", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
      <CategoryList />
       <Divider sx={{height:"2px"}} />
      <AllFilterKey />
    </Stack>
  )
}

export default FilterContainer
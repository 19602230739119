import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseForm from "../../formik/BaseForm";
import { useEditProductValidation } from "../../validation/editProductValidation";
import CustomSelectBox from "../../formik/CustomSelectBox";
import CustomFormikField from "../../formik/CustomFormikField";
import SubmitButton from "../../formik/SubmitButton";
import { useUpdateProduct } from "./hooks/updateProduct";
import { useGetProduct } from "../../hooks/useGetProduct";
import { useGetCategoriesOptions } from "../../helper/selectBox-options";
import { useParams } from "react-router-dom";
import { useSelectBoxOptions } from "../../helper/selectBox-options";
import ItemLocation from "../general/ItemLocation"; 
const SectionWrapper = ({ children }) => (
    <Box sx={{ backgroundColor: "rgb(247,247,247)", p: 2, borderRadius: "10px" }}>
        {children}
    </Box>
);
export const ProductFields = () => {
    const categoriesOptions = useGetCategoriesOptions(); // categories options
    const { TIME_PERIODS_OPTIONS, CONDITIONS_OPTIONS, AVAILABLE_OPTIONS } = useSelectBoxOptions();
    const { editProductValidationSchema } = useEditProductValidation();
    const { id } = useParams();
    const product = useGetProduct(id);
    const { handleSubmit,loading } = useUpdateProduct();
    const { t } = useTranslation("editProduct");
    //initial values 
    const initialValues = {
        name: product?.name || "",
        price: product?.price || "",
        description: product?.description || "",
        per: product?.per || "",
        condition: product?.condition || "",
        category: product?.category || "",
        available: product?.available === undefined ? "" : (product.available ? "Open" : "Close"),
        location: product?.location || ""
    }
    return (

        <BaseForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={editProductValidationSchema}
        >
            <Stack spacing={2} sx={{ margin: "auto", backgroundColor: "text.main", p: 4, borderRadius: "10px" }}>
                <SectionWrapper>
                    <Typography variant="h6" sx={{ mb: 1, color: "primary.main", fontFamily: "Poppins-Bold" }}>
                        {t("formLabels.name")}
                    </Typography>
                    <CustomFormikField name="name" label={t("formLabels.name")} />
                </SectionWrapper>
                <SectionWrapper>
                    <Typography variant="h6" sx={{ mb: 1, color: "primary.main", fontFamily: "Poppins-Bold" }}>
                        {t("formLabels.price")}
                    </Typography>
                    <CustomFormikField name="price" label={t("formLabels.price")} />
                </SectionWrapper>
                <SectionWrapper>
                    <Typography variant="h6" sx={{ mb: 1, color: "primary.main", fontFamily: "Poppins-Bold" }}>
                        {t("formLabels.description")}
                    </Typography>
                    <CustomFormikField name="description" label={t("formLabels.description")} />
                </SectionWrapper>
                <SectionWrapper>
                    <Typography variant="h6" sx={{ mb: 1, color: "primary.main", fontFamily: "Poppins-Bold" }}>
                        {t("formLabels.category")}
                    </Typography>
                    <CustomSelectBox name="category" label={t("formLabels.category")} options={categoriesOptions} />
                </SectionWrapper>
                <SectionWrapper>
                    <Typography variant="h6" sx={{ mb: 1, color: "primary.main", fontFamily: "Poppins-Bold" }}>
                        {t("formLabels.per")}
                    </Typography>
                    <CustomSelectBox name="per" label={t("formLabels.per")} options={TIME_PERIODS_OPTIONS} />
                </SectionWrapper>
                <SectionWrapper>
                    <Typography variant="h6" sx={{ mb: 1, color: "primary.main", fontFamily: "Poppins-Bold" }}>
                        {t("formLabels.condition")}
                    </Typography>
                    <CustomSelectBox name="condition" label={t("formLabels.condition")} options={CONDITIONS_OPTIONS} />
                </SectionWrapper>
                <SectionWrapper>
                    <Typography variant="h6" sx={{ mb: 1, color: "primary.main", fontFamily: "Poppins-Bold" }}>
                        {t("formLabels.available")}
                    </Typography>
                    <CustomSelectBox name="available" label={t("formLabels.available")} options={AVAILABLE_OPTIONS} />
                </SectionWrapper>
                 <SectionWrapper>
                    <Typography variant="h6" sx={{ mb: 1, color: "primary.main", fontFamily: "Poppins-Bold" }}>
                        {t("formLabels.location")}
                    </Typography>
                    <ItemLocation />
                 </SectionWrapper>
                <Box sx={{ mt: 2, maxWidth: "200px" }}>
                    <SubmitButton fullWidth disabled={loading}>
                        {t("save")}
                    </SubmitButton>
                </Box>
            </Stack>
        </BaseForm>

    );
};

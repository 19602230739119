import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomSelectBox from "../../formik/CustomSelectBox";
import CustomFormikField from "../../formik/CustomFormikField";
import {
  useGetCategoriesOptions,
  useSelectBoxOptions,
} from "../../helper/selectBox-options";
const SectionWrapper = ({ children }) => (
  <Box sx={{ backgroundColor: "rgb(247,247,247)", p: 3, borderRadius: "10px" }}>
    {children}
  </Box>
);

const InputFileds = () => {
  const categoriesOptions = useGetCategoriesOptions(); // categories options
  const { TIME_PERIODS_OPTIONS, CONDITIONS_OPTIONS } = useSelectBoxOptions();
  const { t } = useTranslation("add-product");

  return (
    <Stack spacing={3}>
      <SectionWrapper>
        <Typography
          variant="h6"
          sx={{ my: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}
        >
          {t("formFields.category")}
        </Typography>
        <CustomSelectBox
          name="category"
          label={t("formFields.category")}
          options={categoriesOptions}
        />
      </SectionWrapper>
      <SectionWrapper>
        <Typography
          variant="h6"
          sx={{ my: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}
        >
          {t("formFields.productName")}
        </Typography>
        <CustomFormikField
          name="name"
          label={t("formFields.productName")}
          type="text"
        />
      </SectionWrapper>
      <SectionWrapper>
        <Typography
          variant="h6"
          sx={{ my: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}
        >
          {t("formFields.condition")}
        </Typography>
        <CustomSelectBox
          name="condition"
          label={t("formFields.condition")}
          options={CONDITIONS_OPTIONS}
        />
      </SectionWrapper>
      <SectionWrapper>
        <Typography
          variant="h6"
          sx={{ my: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}
        >
          {t("formFields.description")}
        </Typography>
        <CustomFormikField
          name="description"
          label={t("formFields.description")}
          type="text"
          rows={6}
        />
      </SectionWrapper>
      <SectionWrapper>
        <Typography
          variant="h6"
          sx={{ my: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}
        >
          {t("formFields.price")}
        </Typography>
        <CustomFormikField
          name="price"
          label={t("formFields.price")}
          type="number"
        />
      </SectionWrapper>
      <SectionWrapper>
        <Typography
          variant="h6"
          sx={{ my: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}
        >
          {t("formFields.per")}
        </Typography>
        <CustomSelectBox
          name="per"
          label={t("formFields.per")}
          options={TIME_PERIODS_OPTIONS}
        />
      </SectionWrapper>
    </Stack>
  );
};

export default InputFileds;

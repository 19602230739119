import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { LoadingMoreProductsAnimation } from "../general/loading/LoadingMoreProductsAnimation";

const InfiniteScrollList = ({
  fetchData,
  LoadingComponet,
  RenderComponent,
  loadingText,
  noProductsText,
  limit = 10,
  initialPage = 1,
  updateTrigger,
}) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(initialPage);
  const [loading, setLoading] = useState(false);
  const [endItems, setEndItems] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoadingPage(true);
      try {
        const data = await fetchData(initialPage, limit);
        setItems(data);
        setEndItems(data.length < limit);
      } catch (error) {
        // Handle error
      } finally {
        setLoadingPage(false);
      }
    };
    fetchInitialData();
  }, [fetchData, initialPage, limit, updateTrigger]);

  // Fetch additional data
  const fetchAdditionalData = async (pageNumber) => {
    if (!loadingPage) {
      setLoading(true);
    }
    try {
      const data = await fetchData(pageNumber, limit);
      if (data.length === 0) {
        setEndItems(true);
      } else {
        setItems((prevItems) => [...prevItems, ...data]);
        setPage(pageNumber);
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  return (
    <InfiniteScroll
      style={{ overflow: "hidden", padding: "5px", width: "100%", position: "relative" }}
      dataLength={items.length}
      next={() => fetchAdditionalData(page + 1)}
      hasMore={!endItems}
      loader={
        loading && (
          <Grid
            item
            xs={12}
            sx={{
              position: "absolute",
              bottom: "5px",
              right: "0px",
              width: "100%",
            }}
          >
            <Paper
              sx={{
                p: 2,
                width: "100%",
                backgroundColor: "shadow.main",
                color: "primary.main",
                textAlign: "center",
              }}
            >
              <LoadingMoreProductsAnimation loadingText={loadingText} />
            </Paper>
          </Grid>
        )
      }
    >
      <Box>
        {loadingPage ? (
          // render loading component 
          <LoadingComponet />
        ) : items.length > 0 ? (
          // render items component
          <RenderComponent items={items} />
        ) : (
          <Box>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ textAlign: "center", color: "primary.main" }}
            >
              {noProductsText}
            </Typography>
          </Box>
        )}
      </Box>
    </InfiniteScroll>
  );
};

export default InfiniteScrollList;
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDeleteProduct } from "./useDelete";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ConfirmationDialog } from "../../general/ConfirmationDialog";
export const EditDelete = ({ isOwner, productId }) => {
  const { handleDelete, loading } = useDeleteProduct(productId);
  const { t } = useTranslation("product-details");
  const [dialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();
  if (!isOwner) return null;
  const openDeleteConfirmation = () => {
    setDialogOpen(true);
  };
  return (
    isOwner && (
      <>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/editProduct/${productId}`)}
          >
            {t("editDelete.edit")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={openDeleteConfirmation}
            disabled={loading}
          >
            {t("editDelete.delete")}
          </Button>
        </Stack>
        <ConfirmationDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={handleDelete}
          title={t("delete.title")}
          message={t("delete.message")}
          confirmButtonText={t("delete.confirm")}
          severity="error"
        />
      </>
    )
  );
};

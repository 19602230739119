import { useEffect, useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";
import { useSelector } from "react-redux";

export const useFetchProducts = () => {
    const filterKeys = useSelector((state) => state.filter.filterKeys);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const limit = 20;

    // Reset pagination when filterKeys change
    useEffect(() => {
        setProducts([]);
        setCurrentPage(1);
        setHasMore(true);
    }, [filterKeys]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.post(
                    `/products/search?page=${currentPage}&limit=${limit}`,
                    filterKeys,
                    { headers: { "Content-Type": "application/json" } }
                );
                const fetchedProducts = response.data.products;

                if (fetchedProducts.length > 0) {
                    setProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
                    setCurrentPage((prevPage) => prevPage + 1);
                } else {
                    setHasMore(false);
                }
            } catch (error) {
                setHasMore(false);
            }
        };

        if (hasMore) {
            fetchProducts();
        }
    }, [currentPage, filterKeys, hasMore]);

    return products;
};
import { Paper, Typography, Button } from "@mui/material"
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AddCardIcon from '@mui/icons-material/AddCard'
import { useTranslation } from "react-i18next"
import { useStripeConnect } from "./hooks/useStripConnect"

export const ConnectStripeAccount = ({ setIsAccountConnected }) => {
  const { t } = useTranslation("payment-settings")
  const { connectStripe, loadingStates } = useStripeConnect()

  const handleConnectStripe = async () => {
    const success = await connectStripe();
    if (success) {
      // Set the state to true to indicate that the account is connected and the component should be hidden
      setIsAccountConnected(true);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, textAlign: 'center', bgcolor: '#f8f9fa' }}>
      <AccountBalanceWalletIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
      <Typography variant="h6" gutterBottom>
        {t("connect-account.title")}
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 3 }}>
        {t("connect-account.description")}
      </Typography>
      <Button
        variant="contained"
        size="large"
        startIcon={<AddCardIcon />}
        sx={{ px: [2, 4, 4, 4] }}
        onClick={handleConnectStripe}
        disabled={loadingStates.connect}
      >
        {t("connect-account.button")}
      </Button>
    </Paper>
  )
}
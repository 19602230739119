import { Box } from "@mui/material"
import { AddRating } from "./AddRating"
import { AverageRating } from "./AverageRating"
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/slices/AuthUser";
import { useParams } from "react-router-dom";

const RatingContainer = () => {
  const {id} = useSelector(getUser);
  const {userId} = useParams();
  return (
    <Box>
        <AverageRating />
        {userId !== id && (
            <AddRating />
        )}
    </Box>
  )
}

export default RatingContainer
import { Field, ErrorMessage } from 'formik';
import { MenuItem, FormHelperText, FormControl } from '@mui/material';
import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSelect = styled(Select)(({ theme }) => ({
    color: "secondary.main",
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent',
        borderWidth: 1,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent',
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: '#E0E0E0',  // Changed from theme.palette.primary.main
        borderWidth: 1,
    },
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
        borderWidth: 1,
    },
    "&.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.dark,
    },
}));

const CustomSelectBox = ({ name, label, options, ...props }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const selectedOption = options.find(option => option.value === field.value);

                return (
                    <FormControl sx={{width:"100%"}}  error={form.touched[name] && Boolean(form.errors[name])}>
                        <CustomSelect
                            {...field}
                            displayEmpty
                            renderValue={(selected) => {
                                return selected ? selectedOption?.label || label : label;
                            }}
                            size="medium"
                            error={form.touched[name] && Boolean(form.errors[name])}
                            fullWidth
                            {...props}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                        <ErrorMessage name={name}>
                            {(msg) => <FormHelperText error>{msg}</FormHelperText>}
                        </ErrorMessage>
                    </FormControl>
                );
            }}
        </Field>
    );
};

export default CustomSelectBox;

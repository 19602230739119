import { APIProvider, Map, InfoWindow, AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Box } from '@mui/material';
import { useFetchProducts } from './useFetchProducts';
import { ProductItem } from '../../general/ProductItem';

// Main component that renders the map
const MapComponent = () => {
  const API_KEY = import.meta.env.VITE_VERCEL_MAP_API_KEY;

  return (
    <Box style={{ width: '100%', height: '60vh' }}>
      <APIProvider apiKey={API_KEY}>
        <Map
          mapId="bf51a910020fa25a"
          defaultZoom={1}
          defaultCenter={{ lat: 22.54992, lng: 0 }}
          gestureHandling="greedy"
          disableDefaultUI
          zoomControl={true}
          style={{ width: '100%', height: '100%' }}
        >
          <ClusteredProductMarkers />
        </Map>
      </APIProvider>
    </Box>
  );
};

// Component to handle product markers with clustering functionality
const ClusteredProductMarkers = () => {
  const products = useFetchProducts();
  const [markers, setMarkers] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);

  const map = useMap();

  // Create a new MarkerClusterer instance when the map is available
  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({ map });
  }, [map]);

  // Add markers to the clusterer whenever the markers state changes
  useEffect(() => {
    if (!clusterer || !Object.values(markers).length) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // Set marker reference and store it in the markers state
  const setMarkerRef = useCallback((marker, key) => {
    if (!key) {
      return;
    }

    setMarkers((prevMarkers) => {
      if (marker && prevMarkers[key]) return prevMarkers;

      if (marker) {
        return { ...prevMarkers, [key]: marker };
      } else {
        // eslint-disable-next-line no-unused-vars
        const { [key]: _, ...newMarkers } = prevMarkers;
        return newMarkers;
      }
    });
  }, []);

  // Handle marker click to show InfoWindow for the selected product
  const handleMarkerClick = useCallback((product) => {
    setSelectedProduct(product);
  }, []);

  return (
    <>
      {products.map((product) => (
        <ProductMarker
          key={product._id}
          product={product}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}

      {selectedProduct && (
        <InfoWindow
          anchor={markers[selectedProduct.key]}
          onCloseClick={() => setSelectedProduct(null)}
          position={selectedProduct.location}
        >
          <Box style={{ width: '300px', height: '300px' }}>
            <ProductItem product={selectedProduct} />
          </Box>
        </InfoWindow>
      )}
    </>
  );
};

// Component representing a single product marker
const ProductMarker = ({ product, onClick, setMarkerRef }) => {
  const handleClick = useCallback(() => onClick(product), [onClick, product]);

  const ref = useCallback(
    (marker) => {
      const markerKey = `fallback-key-${product._id}`;
      setMarkerRef(marker, markerKey);
    },
    [setMarkerRef, product]
  );

  return (
    <AdvancedMarker position={product.location} ref={ref} onClick={handleClick}>
      <span style={{ fontSize: '25px', cursor: 'pointer' }}>📦</span>
    </AdvancedMarker>
  );
};

export default MapComponent;

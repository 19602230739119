import { Alert, AlertTitle } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
export const AlertMessage = () => {
  const alert = useSelector((state) => state.alertDetails);
  const dispatch = useDispatch();
  // handle on close alert
  const handleClose = () => {
    dispatch(setAlertDetails({ type: "", message: "" }));
  };
  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        dispatch(setAlertDetails({ type: "", message: "" }));
      }, 30000);
    }
  }, [alert.message, dispatch]);
  return (
    <>
      {alert.message ? (
        <Alert
          severity={alert.type}
          sx={{
            position: "fixed",
            top: "50px",
            left: "20px",
            zIndex: 3000,
            width: "300px",
            py: 2,

          }}
          onClose={handleClose}
        >
          <AlertTitle>{alert.type}</AlertTitle>
          {alert.message}
        </Alert>
      ) : null}
    </>
  );
};

import { Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";
import { useLanguageName } from "../../helper/IntlFormating";
import { useState } from "react";
import { ConfirmationDialog } from "../general/ConfirmationDialog";

// Reusable SettingsItem component
const SettingsItem = ({
  label,
  value,
  icon: Icon,
  to,
  onClick,
  isExternalLink = false,
  iconColor = "secondary.main",
}) => {
  const { t } = useTranslation("settings");

  const commonSx = {
    backgroundColor: "rgb(247,247,247)",
    mt: 5,
    p: 2,
    cursor: "pointer",
    textDecoration: "none",
  };

  const linkProps = to ? {
    as: Link,
    to,
    ...(isExternalLink ? { target: "_blank" } : {}),
  } : {};

  const eventProps = onClick ? { onClick } : {};

  const content = (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={commonSx}
      {...linkProps}
      {...eventProps}
    >
      <Typography
        variant="body1"
        align="center"
        sx={{ color: "secondary.main", fontWeight: "bold" }}
      >
        {t(label)}
      </Typography>
      {Icon && (
        <Icon
          sx={{
            color: iconColor,
            fontSize: "30px",
            cursor: "pointer"
          }}
        />
      )}
      {value && (
        <Typography
          variant="body1"
          align="center"
          sx={{ color: "secondary.main", fontWeight: "bold" }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );

  return content;
};

export const ListSettings = () => {
  const authUser = useSelector(getUser);
  const languageName = useLanguageName();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const deleteAccountUrl = "https://docs.google.com/forms/d/e/1FAIpQLSeh9PGWHt_2fpDyp3LTb5Zc6DSELeGj89cavFrcBageNkHYKw/viewform";
  const { t } = useTranslation("settings");
  const handleDeleteAccountClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDeleteAccount = () => {
    window.open(deleteAccountUrl, '_blank');
  };

  const settingsItems = [
    {
      label: "list-settings.info",
      to: `/account-info/${authUser?.id}`,
      icon: ArrowForwardIcon,
    },
    {
      label: "list-settings.address",
      to: "/save-address",
      icon: ArrowForwardIcon,
    },
    {
      label: "list-settings.Language",
      value: languageName,
      icon: null,
    },
    {
      label: "list-settings.Country",
      value: authUser?.user?.address?.country || "?",
      icon: null,
    },
    {
      label: "list-settings.payment-settings",
      to: "/payment-settings",
      icon: ArrowForwardIcon,
    },
    {
      label: "list-settings.delete-account",
      onClick: handleDeleteAccountClick,
      icon: DeleteIcon,
      iconColor: "primary.main",
      isExternalLink: true,
    },
  ];

  return (
    <>
      <Stack spacing={2}>
        {settingsItems.map((item, index) => (
          <SettingsItem
            key={index}
            {...item}
          />
        ))}
      </Stack>
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleConfirmDeleteAccount}
        title={t("delete-account.confirmTitle")}
        message={t("delete-account.confirmMessage")}
        confirmButtonText={t("delete-account.confirm")}
        severity="error"
      />
    </>
  );
};

import { Box, Button, Typography } from "@mui/material";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const mapStyle = {
  width: "100%",
  height: "300px",
};

export const SelectLocation = ({
  location,
  onLocationChange,
  useNearbyLocation = false,
  mapId = "default_map_id",
  autoDetectButton = true,
  mapStyleOverride = mapStyle,
}) => {
  const { t } = useTranslation("selectLocation");
  const ApiKey = import.meta.env.VITE_VERCEL_MAP_API_KEY;
  const [errorMessage, setErrorMessage] = useState(null);

  const getApproximateLocation = async ({ lat, lng }) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ApiKey}`;

    try {
      const response = await axios.get(geocodeUrl);
      const results = response.data.results;
      if (results.length > 0) {
        // Find a nearby location: for simplicity, take the first result or a specific type
        const nearbyPlace = results.find((place) =>
          place.types.includes("locality") ||
          place.types.includes("political") ||
          place.types.includes("plus_code") ||
          place.types.includes("route") ||
          place.types.includes("administrative_area_level_2") ||
          place.types.includes("administrative_area_level_1") ||
          place.types.includes("administrative_area_level_1") ||
          place.types.includes("country")

        )

        if (nearbyPlace) {
          const { location } = nearbyPlace.geometry;
          return { lat: location.lat, lng: location.lng }; // Approximate location
        }
      }
      throw new Error("No valid nearby location found");
    } catch (error) {
      return null; // Fallback to no change
    }
  };

  // Example of using the function
  const handleAutoDetectLocation = () => {
    if (navigator.geolocation) {
      setErrorMessage(null);
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const actualLocation = { lat: latitude, lng: longitude };
        if (useNearbyLocation) {
          const approximateLocation = await getApproximateLocation(actualLocation);
          if (approximateLocation) {
            onLocationChange(approximateLocation);
          } else {
            onLocationChange(null);
            setErrorMessage(t("nearbyLocationError"));
          }
        } else {
          onLocationChange(actualLocation);
        }
      });
    }
  };
  return (
    <APIProvider apiKey={ApiKey}>
      <Map
        mapId={mapId}
        zoom={2}
        center={location || { lat: 0, lng: 0 }}
        onClick={(event) => {
          const position = event.detail.latLng;
          onLocationChange(position);
        }}
        style={mapStyleOverride}
      >
        {location ? <AdvancedMarker position={location} /> : null}
      </Map>
      {autoDetectButton && (
        <Box>
          <Button onClick={handleAutoDetectLocation} sx={{ textAlign: "center" }}>
            {useNearbyLocation ? t("useNearbyLocation") : t("autoDetectLocation")}
          </Button>
          {errorMessage && (
            <Typography color="error" variant="caption" component="h6" sx={{ mt: 1 }}>
              {errorMessage}
            </Typography>
          )}
        </Box>
      )}
    </APIProvider>
  );
};

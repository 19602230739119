import { ErrorMessage, Field } from 'formik'; 
import { DatePicker } from "@mui/x-date-pickers/DatePicker"; 
import { styled } from "@mui/material/styles"; 
import dayjs from 'dayjs'; 
import { Box } from '@mui/material'; 
 
const StyledDatePicker = styled(DatePicker)(({ theme, error }) => ({ 
  width: "100%", 
  color: theme.palette.secondary.main, 
  '& .MuiOutlinedInput-root': { 
    borderRadius: "10px", 
    backgroundColor: "white", 
    color: theme.palette.secondary.main, 
    '& fieldset': { 
      borderColor: error ? theme.palette.error.main : 'transparent', 
    }, 
    '&:hover fieldset': { 
      borderColor: error ? theme.palette.error.main : 'transparent', 
    }, 
    '&.Mui-focused fieldset': { 
      borderColor: error ? theme.palette.error.main : 'transparent', 
    }, 
    '& .MuiInputBase-input::placeholder': { 
      color: 'black', 
      fontSize: '12px', 
    }, 
  }, 
  '& .MuiFormLabel-root': { 
    color: 'black', 
  }, 
})); 
 
const CustomDatePicker = ({ name, label, shouldDisableDate, minDate, disabled = false, ...props }) => { 
  const today = new Date(); 

  return ( 
    <> 
      <Field name={name}> 
        {({ field, form: { setFieldValue, errors, touched } }) => { 
          const hasError = Boolean(errors[name] && touched[name]); 

          return ( 
            <StyledDatePicker 
              {...field} 
              label={label} 
              value={field.value} 
              onChange={(date) => setFieldValue(name, date)} 
              minDate={minDate || dayjs(today)} 
              shouldDisableDate={shouldDisableDate} 
              disabled={disabled} 
              error={hasError} 
              {...props} 
            /> 
          ); 
        }} 
      </Field> 
      <ErrorMessage name={name} component={Box} sx={{ color: "red", fontSize: "12px" }} /> 
    </> 
  ); 
}; 
 
export default CustomDatePicker;

import { Box, Typography } from "@mui/material";
import { ConnectStripeAccount } from "../components/payment-settings/ConnectStripeAccount";
import { AccountSetting } from "../components/payment-settings/AccountSetting";
import { useTranslation } from "react-i18next";
import { useGetUser } from "../hooks/useGetUser";
import { useState, useEffect } from "react";
export const PaymentSettings = () => {
  const { user } = useGetUser();
  console.log(user);
  const { t } = useTranslation("payment-settings");
  const isAccountCommplete = user?.isOnboarded;
  const [isAccountConnected, setIsAccountConnected] = useState(false);

  useEffect(() => {
    setIsAccountConnected(!!user?.stripeAccountId);
  }, [user]);
  return (
    <Box sx={{ py: 5 }}>
      <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            textAlign: "center",
            mb: 5,
            fontFamily: "Poppins-Bold",
            color: "primary.main",
          }}
        >
          {t("title")}
        </Typography>
        {!isAccountConnected ? (
          <ConnectStripeAccount setIsAccountConnected={setIsAccountConnected} />
        ) : (
          <AccountSetting isAccountCommplete={isAccountCommplete} />
        )}
      </Box>
    </Box>
  );
};

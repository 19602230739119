import { useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
import { fetchUser } from "../../../redux/slices/AuthUser";
import { useDispatch } from "react-redux";

export const useStripeConnect = () => {
  const dispatch = useDispatch();
  const { showAlert } = useShowAlert();
  const [loadingStates, setLoadingStates] = useState({
    connect: false,
    onboarding: false,
    dashboard: false
  });

  const setLoading = (key, value) => {
    setLoadingStates(prev => ({ ...prev, [key]: value }));
  };

  // Connects the user's Stripe account
  const connectStripe = async () => {
    try {
      setLoading('connect', true);
      const response = await axiosInstance.post("/stripe/account");
      dispatch(fetchUser());
      showAlert("success", response.data.message);
      return true;
    } catch (error) {
      showAlert("error", error.response.data.error);
      return false;
    } finally {
      setLoading('connect', false);
    }
  };
// Generates a link to the Stripe onboarding page
  const onBoardingLink = async () => {
    try {
      setLoading('onboarding', true);
      const response = await axiosInstance.post("/stripe/account-link");
      const url = response.data.url;
      window.open(url, "_blank");
    } catch (error) {
      showAlert("error", error.response.data.error);
    } finally {
      setLoading('onboarding', false);
    }
  };
// Redirects the user to the Stripe dashboard
  const goToStripeDashboard = async () => {
    try {
      setLoading('dashboard', true);
      const response = await axiosInstance.get("stripe/dashboard-link");
      const url = response.data.url;
      window.open(url, "_self");
    } catch (error) {
      showAlert("error", error.response.data.error);
    } finally {
      setLoading('dashboard', false);
    }
  };

  return {
    connectStripe,
    onBoardingLink,
    goToStripeDashboard,
    loadingStates
  };
};
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUser, getUser } from "../../redux/slices/AuthUser";
import axiosInstance from "../../axios/axiosInstance";
import { useTranslation } from "react-i18next";
import { useShowAlert } from "../../hooks/useShowAlert";

export const NotifyMe = () => {
  const { showAlert } = useShowAlert();
  const { t } = useTranslation("product-details");
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [showNotifyMe, setShowNotifyMe] = useState(false);
  const [nextAvailableDate, setNextAvailableDate] = useState(null);
  const authUser = useSelector(getUser); // get user from redux
  const { productID } = useParams();

  useEffect(() => {
    axiosInstance
      .get(`/products/${productID}/unavailable-dates`)
      .then((res) => {
        const currentDate = dayjs();
        const unavailableDates = res.data.unavailableDates;
        let isProductAvailable = true;
        let earliestAvailableDate = null;

        if (currentDate.isValid()) {
          for (let i = 0; i < unavailableDates.length; i++) {
            const startDate = dayjs(unavailableDates[i].start_date);
            const endDate = dayjs(unavailableDates[i].end_date);

            if (
              (currentDate.isAfter(startDate) &&
                currentDate.isBefore(endDate)) ||
              currentDate.isSame(startDate) ||
              currentDate.isSame(endDate)
            ) {
              isProductAvailable = false;
              if (!earliestAvailableDate || startDate.isAfter(currentDate)) {
                earliestAvailableDate = startDate;
              }
              break;
            }
          }
        }

        setShowNotifyMe(isProductAvailable);
        setNextAvailableDate(
          earliestAvailableDate
            ? earliestAvailableDate.format("MMM DD, YYYY")
            : null
        );
      });
  }, [productID]);

  useEffect(() => {
    if (authUser) {
      const isProductInWatchlist =
        authUser?.user?.watchlist?.includes(productID);
      setChecked(isProductInWatchlist);
    }
  }, [authUser, productID]);

  const handleNotifyMe = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      axiosInstance
        .post(`/user/add-to-watchlist/${productID}`, {})
        .then((res) => {
          showAlert("success", res.data.message);
          dispatch(fetchUser());
        })
        .catch((err) => {
          showAlert("error", err.response.data.error);
        });
    } else {
      axiosInstance
        .delete(`/user/remove-from-watchlist/${productID}`)
        .then(() => {
          dispatch(fetchUser());
        });
    }
  };

  return (
    <>
      {!showNotifyMe ? (
        <Box>
          <Stack
            direction="row"
            sx={{
              my: 1,
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <Box>
              <Checkbox checked={!!checked} onChange={handleNotifyMe} />
            </Box>
            <Box>
              <Typography
                variant="body1"
                component="span"
                sx={{ py: 4, lineHeight: "30px", color: "primary.main" }}
              >
                {t("notifyMe")}
              </Typography>
            </Box>
          </Stack>
          {nextAvailableDate && (
            <Typography variant="body1" sx={{ color: "secondary.main" }}>
              {t("nextAvailable")}:{" "}
              <Typography
                variant="body1"
                as="span"
                sx={{ color: "primary.main", fontWeight: "bold" }}
              >
                {nextAvailableDate}
              </Typography>
            </Typography>
          )}
        </Box>
      ) : null}
    </>
  );
};
